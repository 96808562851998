<template lang="pug">
.top-header-logo
  v-link.is-flex(to="index")
    picture
      source(
        media="(min-width: 1025px)",
        :srcset="`/images/normal-webp/logos/full-logo.webp 1x, /images/hi-res-webp/logos/full-logo@2x.webp 2x`",
        type="image/webp"
      )
      source(
        media="(min-width: 1025px)",
        :srcset="`/images/normal/logos/full-logo.jpg 1x, /images/hi-res/logos/full-logo@2x.jpg 2x`",
        type="image/jpeg"
      )


      source(
        media="(min-width: 768px) and (max-width: 1024px)",
        :srcset="`/images/normal-webp/logos/laptop-logo.webp 1x, /images/hi-res-webp/logos/laptop-logo@2x.webp 2x`",
        type="image/webp"
      )
      source(
        media="(min-width: 768px) and (max-width: 1024px)",
        :srcset="`/images/normal/logos/laptop-logo.jpg 1x, /images/hi-res/logos/laptop-logo@2x.jpg 2x`",
        type="image/jpeg"
      )


      source(
        media="(max-width: 767px)",
        :srcset="`/images/normal-webp/logos/mob-logo.webp 1x, /images/hi-res-webp/logos/mob-logo@2x.webp 2x`",
        type="image/webp"
      )
      source(
        media="(max-width: 767px)",
        :srcset="`/images/normal/logos/mob-logo.jpg 1x, /images/hi-res/logos/mob-logo@2x.jpg 2x`",
        type="image/jpeg"
      )


      source(
        :srcset="`/images/normal-webp/logos/mob-logo.webp 1x, /images/hi-res-webp/logos/mob-logo@2x.webp 2x`",
        type="image/webp"
      )


      img(
        :srcset="`/images/normal/logos/mob-logo.jpg 767w, /images/normal/logos/laptop-logo.jpg 1024w, /images/normal/logos/full-logo.jpg 1920w`",
        :src="`/images/normal/logos/mob-logo.jpg`",
        :alt="'Company logo'"
      )

</template>

<script>
export default {
  name: 'TopHeaderLogo'
}
</script>

<style lang="sass" scoped>
.top-header-logo
  max-width: 100%
  img
    width: auto
    height: auto
  picture
    line-height: 0
</style>
